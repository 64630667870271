.button{
    background-color: #12699c;
    color: white;
    width: 100%;
    margin-top: 10px;
    border: 0px;
    padding: 8px;
    border-radius: 10px;
    font-weight: 700;
}
.login-container {
    background-color: #fff;
    height: 350px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adding box shadow */
  }
  